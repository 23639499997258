<script>
import Gen from "@helper/Gen";
import BOGen from "@helper/BOGen";
import GlobalVue from "@helper/Global.vue";
import "@backend/css/pages/login-register-lock.css";

export default {
  extends: GlobalVue,
  data() {
    return {
      input: {},
    };
  },
  computed: {
    yearcopy() {
      return Gen.yearCopy(this.web.yearcopy);
    },
  },
  mounted() {
    this.refreshVariable();
  },
  methods: {
    submit(e) {
      if (e.btnLoading()) return;
      BOGen.apirest(
        "/login",
        this.input,
        (err, resp) => {
          e.btnUnloading();
          if (err) return console.log(err);
          if (resp.error) {
            return Gen.info(resp.message, "danger");
          }
          Gen.putStorage("user", resp.user);
          Gen.putStorage("botk", resp.token);
          localStorage.setItem("level", resp.level);
          Gen.info(resp.message, "success", 300).then(() => {
            this.$router.push({
              name: "BoDashboard",
            });
            location.reload();
          });
        },
        "POST"
      );
    },
  },
};
</script>

<template>
  <section id="wrapper">
    <div
      class="login-register"
      :style="
        'background-image: url(\'' +
          assets('bo_images', 'login-register2.jpg') +
          '\');'
      "
    >
      <div class="login-box">
        <div class="card mb-0">
          <div class="card-body">
            <VForm
              @resp="submit"
              class="form-horizontal form-material"
              id="loginform"
              action="index.html"
            >
              <h5 class="text-center m-b-10">Sign In</h5>
              <div class="text-center m-b-20">
                <img
                  :src="assets('bo_images', 'logo-bo.png')"
                  alt="homepage"
                  class="dark-logo"
                />
              </div>

              <div class="info"></div>
              <div class="form-group ">
                <div class="col-xs-12">
                  <input
                    class="form-control"
                    onkeydown="return userNameKey(event)"
                    type="text"
                    v-model="input.username"
                    name="username"
                    required
                    placeholder="username"
                  />
                </div>
              </div>
              <div class="form-group m-b-30">
                <div class="col-xs-12">
                  <input
                    class="form-control"
                    type="password"
                    v-model="input.password"
                    name="password"
                    required
                    placeholder="password"
                  />
                </div>
              </div>
              <div class="form-group text-center mb-0">
                <div class="col-xs-12 text-center">
                  <button
                    class="btn btn-block btn-info btn-rounded btn-loading"
                    type="submit"
                  >
                    Sign In
                  </button>
                  <router-link
                    :to="{ name: 'BoPassRecover' }"
                    class="d-inline-block mt-2"
                    ><strong>Lupa Password?</strong></router-link
                  >
                </div>
              </div>
            </VForm>
          </div>
          <div class="col-sm-12 text-center copyright">
            <p>
              {{ yearcopy }} &copy; SDI Al Hidayah. All Rights Reserved. <br />
              Powered by
              <a target="_blank" href="http://lingkar9.com/">Lingkar9</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
